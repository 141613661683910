import { Components, Theme } from '@mui/material'
import { alertClasses } from '@mui/material/Alert'
import { buttonClasses } from '@mui/material/Button'
import { common, green, grey, indigo, orange, red } from '@mui/material/colors'
import { iconButtonClasses } from '@mui/material/IconButton'
import { svgIconClasses } from '@mui/material/SvgIcon'
import React from 'react'

import ErrorIcon from 'components/Icons/Error'
import InfoIcon from 'components/Icons/Info'
import SuccessIcon from 'components/Icons/Success'
import WarningIcon from 'components/Icons/Warning'
import palette from 'theme/palette'

const MuiAlert: Components<Theme>['MuiAlert'] = {
  defaultProps: {
    iconMapping: {
      error: <ErrorIcon />,
      info: <InfoIcon />,
      success: <SuccessIcon />,
      warning: <WarningIcon />,
    },
  },
  styleOverrides: {
    action: ({ theme }) => ({
      [`& > .${buttonClasses.text}`]: {
        marginLeft: theme.spacing(3),
      },
      [`& > .${iconButtonClasses.root}`]: {
        [`& > .${svgIconClasses.root}`]: {
          fontSize: 16,
        },
        marginLeft: theme.spacing(2),
        padding: 4,
      },
      padding: 0,
    }),
    filled: {
      [`& .${alertClasses.action} .${buttonClasses.text}`]: {
        [`&:hover`]: {
          color: grey['400'],
        },
        color: common.white,
      },
      [`& .${alertClasses.message}, .${alertClasses.icon}`]: {
        color: common.white,
      },
    },
    filledInfo: {
      backgroundColor: indigo['700'],
    },
    filledSuccess: {
      backgroundColor: green.A700,
    },
    icon: ({ theme }) => ({
      marginRight: theme.spacing(2),
      padding: 0,
    }),
    message: {
      color: palette.text.primary,
      fontWeight: 600,
      padding: 0,
    },
    root: ({ theme }) => ({
      alignItems: 'flex-start',
      padding: theme.spacing(3, 4),
    }),
    standardError: {
      [`& .${alertClasses.icon}`]: {
        color: red['500'],
      },
      backgroundColor: red['50'],
    },
    standardInfo: {
      [`& .${alertClasses.icon}`]: {
        color: indigo['700'],
      },
      backgroundColor: indigo['50'],
    },
    standardSuccess: {
      [`& .${alertClasses.icon}`]: {
        color: green.A700,
      },
      backgroundColor: green['50'],
    },
    standardWarning: {
      [`& .${alertClasses.icon}`]: {
        color: orange['500'],
      },
      backgroundColor: orange['50'],
    },
  },
}

export default MuiAlert
