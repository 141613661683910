import { Components, Theme } from '@mui/material'
import { grey } from '@mui/material/colors'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'

const MuiOutlinedInput: Components<Theme>['MuiOutlinedInput'] = {
  styleOverrides: {
    adornedEnd: ({ theme }) => ({
      paddingRight: theme.spacing(2),
    }),
    adornedStart: ({ theme }) => ({
      paddingLeft: theme.spacing(2),
    }),
    input: ({ theme }) => ({
      '&, &[type=text], &[type=text][disabled]': {
        [`.${outlinedInputClasses.adornedEnd} &`]: {
          paddingRight: theme.spacing(0),
        },

        padding: theme.spacing(2),
      },
    }),
    inputAdornedStart: {
      '&, &[type=text], &[type=text][disabled]': {
        paddingLeft: 0,
      },
    },
    multiline: ({ theme }) => ({
      padding: theme.spacing(2),
    }),
    notchedOutline: ({ theme }) => ({
      borderColor: grey['300'],
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(2),
    }),
    root: {
      [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
        {
          borderWidth: 1,
        },
      [`&:hover:not(.${outlinedInputClasses.focused}) .${outlinedInputClasses.notchedOutline}`]:
        {
          borderColor: grey['400'],
        },
    },
  },
}

export default MuiOutlinedInput
