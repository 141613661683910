import { Components } from '@mui/material'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import { selectClasses } from '@mui/material/Select'

const MuiSelect: Components['MuiSelect'] = {
  styleOverrides: {
    icon: {},
    iconOutlined: {
      right: 8,
      top: 'calc(50% - 8px)',
    },
    nativeInput: {
      boxSizing: 'border-box',
    },
    outlined: {
      [`&.${outlinedInputClasses.inputAdornedEnd} ~ .${selectClasses.iconOutlined}`]:
        {
          marginLeft: -16,
          position: 'static',
        },
      [`&.${outlinedInputClasses.inputAdornedEnd}`]: {
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
        paddingRight: 24,
      },
    },
  },
}

export default MuiSelect
