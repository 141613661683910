import { Components, Theme } from '@mui/material'
import { menuItemClasses } from '@mui/material/MenuItem'

const MuiMenuItem: Components<Theme>['MuiMenuItem'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      [`&.${menuItemClasses.selected}`]: {
        backgroundColor: 'transparent',
        backgroundImage: `repeating-linear-gradient(90deg, ${theme.palette.primary.main} 0px, ${theme.palette.primary.main} 2px, transparent 2px, transparent 100%)`,
        color: theme.palette.primary.main,
      },
      fontWeight: 600,
    }),
  },
}

export default MuiMenuItem
