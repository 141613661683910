const removeLocalStorageItem = (key: string, onError?: () => void) => {
  try {
    localStorage.removeItem(key)
  } catch (error) {
    onError?.()
  }
}

const setLocalStorageItem = (
  key: string,
  value: string,
  onError?: () => void
) => {
  try {
    localStorage.setItem(key, value)
  } catch (error) {
    onError?.()
  }
}

const getLocalStorageItem = (
  key: string,
  onError?: () => void
): string | null => {
  let itemValue = null

  try {
    itemValue = localStorage.getItem(key)
  } catch (error) {
    onError?.()
  }

  return itemValue
}

export { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem }
