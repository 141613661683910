import { Components, Theme } from '@mui/material'

const MuiAlertTitle: Components<Theme>['MuiAlertTitle'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      ...theme.typography.h5,
    }),
  },
}

export default MuiAlertTitle
