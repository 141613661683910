import { Components, Theme } from '@mui/material'
import { blueGrey } from '@mui/material/colors'

import palette from 'theme/palette'

const MuiSnackbarContent: Components<Theme>['MuiSnackbarContent'] = {
  defaultProps: {
    'data-testid': 'notification-content',
  },
  styleOverrides: {
    action: ({ theme }) => ({
      '& button': {
        marginTop: theme.spacing(-1),
      },
      color: blueGrey['500'],
      marginRight: 0,
      paddingLeft: theme.spacing(2),
    }),
    message: ({ theme }) => ({
      '& > span': {
        '& svg': {
          marginRight: theme.spacing(2),
        },
        alignItems: 'flex-start',
      },
      color: palette.text.primary,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '16px',
      padding: 0,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(70),
      },
    }),
    root: {
      alignItems: 'flex-start',
    },
  },
}

export default MuiSnackbarContent
