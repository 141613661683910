import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import React from 'react'

function DialogClose(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path d="M14.3 1.71C13.91 1.32 13.28 1.32 12.89 1.71L8 6.59L3.11 1.7C2.72 1.31 2.09 1.31 1.7 1.7C1.31 2.09 1.31 2.72 1.7 3.11L6.59 8L1.7 12.89C1.31 13.28 1.31 13.91 1.7 14.3C2.09 14.69 2.72 14.69 3.11 14.3L8 9.41L12.89 14.3C13.28 14.69 13.91 14.69 14.3 14.3C14.69 13.91 14.69 13.28 14.3 12.89L9.41 8L14.3 3.11C14.68 2.73 14.68 2.09 14.3 1.71Z" />
    </SvgIcon>
  )
}

export default DialogClose
