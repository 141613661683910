import { Components, Theme } from '@mui/material'
import { blueGrey, common, grey, indigo } from '@mui/material/colors'
import { switchClasses } from '@mui/material/Switch'

import palette from 'theme/palette'

const MuiSwitch: Components<Theme>['MuiSwitch'] = {
  defaultProps: {
    color: 'primary',
    disableRipple: true,
  },
  styleOverrides: {
    colorPrimary: {
      [`&.${switchClasses.checked}`]: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
        [`& + .${switchClasses.track}`]: {
          borderColor: palette.primary.main,
        },
        [`&.${switchClasses.disabled} + .${switchClasses.track}`]: {
          borderColor: grey['400'],
        },
        [`&:hover + .${switchClasses.track}`]: {
          backgroundColor: indigo['700'],
          borderColor: indigo['700'],
        },
      },
      [`&.${switchClasses.disabled} + .${switchClasses.track}`]: {
        backgroundColor: 'transparent',
        border: `1px solid ${grey['400']}`,
      },
    },
    input: ({ theme }) => ({
      [`&:focus + .${switchClasses.thumb}`]: {
        backgroundColor: blueGrey[50],
        border: '4px solid white',
        transition: theme.transitions.create(['background-color', 'border']),
      },
      [`&:focus:checked + .${switchClasses.thumb}`]: {
        backgroundColor: palette.primary.main,
      },
    }),
    root: {
      height: 32,
      padding: 6,
      width: 44,
    },
    sizeSmall: {
      [`& .${switchClasses.switchBase}`]: {
        top: -1,
      },
    },
    switchBase: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      [`&.${switchClasses.checked}`]: {
        [`& + .${switchClasses.track}`]: {
          opacity: 1,
        },
        color: common.white,
        transform: 'translateX(12px)',
      },
      left: 0,

      padding: 6,

      top: 0,
    },
    thumb: {
      boxSizing: 'border-box',
      height: 18,
      margin: 1,
      width: 18,
    },
    track: ({ theme }) => ({
      backgroundColor: blueGrey[50],
      border: `1px solid ${blueGrey[100]}`,
      borderRadius: 10,
      boxSizing: 'border-box',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    }),
  },
}

export default MuiSwitch
