import { Components, Theme } from '@mui/material'

const MuiDialogActions: Components<Theme>['MuiDialogActions'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(2, 4),
    }),
  },
}

export default MuiDialogActions
