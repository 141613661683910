import { Components, Theme } from '@mui/material'
import { autocompleteClasses } from '@mui/material/Autocomplete'
import { indigo } from '@mui/material/colors'
import { iconClasses } from '@mui/material/Icon'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import React from 'react'

import RemoveIcon from 'components/Icons/Remove'

const MuiAutocomplete: Components<Theme>['MuiAutocomplete'] = {
  defaultProps: {
    clearIcon: <RemoveIcon />,
  },
  styleOverrides: {
    clearIndicator: {
      [`button& svg.${iconClasses.root}`]: {
        fontSize: 16,
      },
      padding: 2,
    },
    inputRoot: {
      padding: 2,
    },
    option: ({ theme }) => ({
      '&.Mui-focused': {
        backgroundColor: indigo['50'],
      },
      '&:active': {
        backgroundColor: indigo['100'],
      },
      '&[aria-disabled="true"]': {
        opacity: 0.5,
        pointerEvents: 'none',
      },
      '&[aria-selected="true"]': {
        '&:before': {
          backgroundColor: indigo.A700,
          bottom: 0,
          content: '""',
          display: 'block',
          left: 0,
          position: 'absolute',
          top: 0,
          width: 2,
        },
        backgroundColor: 'transparent',
        color: indigo.A700,
        position: 'relative',
      },
      '&[data-option-index="0"] > .GeoSuggestManualOption > div:first-of-type':
        {
          display: 'none',
        },
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '16px',

      paddingBottom: theme.spacing(2),

      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(2),
    }),
    popupIndicator: ({ theme }) => ({
      marginLeft: theme.spacing(1),
      marginRight: 0,
      padding: 0,
    }),
    /* eslint-disable perfectionist/sort-objects */
    root: ({ theme }) => ({
      [`& .${outlinedInputClasses.root}`]: {
        padding: 0,

        [`& .${autocompleteClasses.input}`]: {
          padding: theme.spacing(2),
        },
      },
      [`& .${autocompleteClasses.input}`]: {
        padding: 8,
      },
      [`& .${outlinedInputClasses.adornedStart}`]: {
        paddingLeft: 8,
      },
      [`& .${outlinedInputClasses.adornedEnd}`]: {
        paddingRight: 8,
      },
    }),
    /* eslint-enable perfectionist/sort-objects */
  },
}

export default MuiAutocomplete
