import { Components, Theme } from '@mui/material'

const MuiLink: Components<Theme>['MuiLink'] = {
  defaultProps: {
    underline: 'hover',
  },
  styleOverrides: {
    button: {
      backgroundImage: 'none',
      backgroundRepeat: 'repeat',
      height: 'auto',
    },
  },
}

export default MuiLink
