import { Components, Theme } from '@mui/material'

const MuiMenu: Components<Theme>['MuiMenu'] = {
  defaultProps: {
    transformOrigin: {
      horizontal: 'left',
      vertical: -4,
    },
  },
}

export default MuiMenu
