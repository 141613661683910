import { Components, Theme } from '@mui/material'

const MuiAvatar: Components<Theme>['MuiAvatar'] = {
  styleOverrides: {
    root: {
      height: 32,
      width: 32,
    },
  },
}

export default MuiAvatar
