import { Components, Theme } from '@mui/material'
import { inputBaseClasses } from '@mui/material/InputBase'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'

import palette from 'theme/palette'
import { fontSize } from 'theme/typography'

const MuiInputBase: Components<Theme>['MuiInputBase'] = {
  styleOverrides: {
    adornedEnd: ({ theme }) => ({
      paddingRight: theme.spacing(2),
    }),
    input: ({ theme }) => ({
      '&[type=text]': {
        border: 0,
        padding: theme.spacing(2, 2, 2, 0),
      },
      [`&.${inputBaseClasses.disabled}`]: {
        background: 'none',
        WebkitTextFillColor: palette.text.disabled,
      },

      [`&.${inputBaseClasses.inputMultiline}`]: {
        padding: 0,
      },
      boxSizing: 'border-box',
      height: 'auto',
    }),
    multiline: ({ theme }) => ({
      '& input': {
        paddingBottom: '0 !important',
        paddingTop: '0 !important',
      },

      padding: theme.spacing(2, 0),
    }),
    root: ({ theme }) => ({
      [`&.Mui-focused.${inputBaseClasses.readOnly} > .${outlinedInputClasses.notchedOutline}`]:
        {
          borderColor: theme.palette.grey[300],
        },
      color: palette.text.primary,
      fontSize,
      fontWeight: 600,

      lineHeight: '16px',
    }),
  },
}

export default MuiInputBase
