import { Components, Theme } from '@mui/material'
import { popoverClasses } from '@mui/material/Popover'

const MuiPopover: Components<Theme>['MuiPopover'] = {
  defaultProps: {
    transformOrigin: {
      horizontal: 'left',
      vertical: -4,
    },
  },
  styleOverrides: {
    root: ({ theme }) => ({
      [`&.confirm .${popoverClasses.paper}`]: {
        '& button': {
          '& + button': {
            marginLeft: theme.spacing(4),
          },
        },
        '& h5': {
          marginBottom: theme.spacing(2),
          textAlign: 'initial',
        },
        '& p': {
          marginBottom: theme.spacing(2),
        },
        padding: theme.spacing(2),
        textAlign: 'right',
      },
    }),
  },
}

export default MuiPopover
