import { Components, tableCellClasses, Theme } from '@mui/material'

import { fontSize } from 'theme/typography'

const MuiTableCell: Components<Theme>['MuiTableCell'] = {
  styleOverrides: {
    head: {
      fontSize,
      fontWeight: 500,
    },
    root: ({ theme }) => ({
      [`&:not(.${tableCellClasses.paddingNone})`]: {
        padding: theme.spacing(2, 1.5),
      },

      fontSize,
    }),
    stickyHeader: {
      backgroundColor: 'transparent',
    },
  },
}

export default MuiTableCell
