import { Components, Theme } from '@mui/material'
import { grey } from '@mui/material/colors'

const MuiToggleButtonGroup: Components<Theme>['MuiToggleButtonGroup'] = {
  styleOverrides: {
    grouped: () => ({
      '&:not(:first-of-type)': {
        borderBottomLeftRadius: 0,
        borderLeft: 'none',
        borderTopLeftRadius: 0,
      },
      '&:not(:last-child)': {
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
      },

      border: 'none',

      borderRadius: 4,
    }),
    root: {
      border: `1px solid ${grey['300']}`,
      boxSizing: 'border-box',
      height: 32,
      padding: '3px',
    },
  },
}

export default MuiToggleButtonGroup
