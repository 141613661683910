import { Components, Theme } from '@mui/material'
import { indigo } from '@mui/material/colors'
import { iconButtonClasses } from '@mui/material/IconButton'

const MuiRadio: Components<Theme>['MuiRadio'] = {
  defaultProps: {
    color: 'primary',
  },
  styleOverrides: {
    colorPrimary: {
      '&:hover': {
        backgroundColor: indigo[50],
        color: indigo[700],
      },
    },
    root: ({ theme }) => ({
      [`& .${iconButtonClasses.root}`]: {
        fontSize: 16,
      },
      borderRadius: theme.spacing(1),

      padding: 4,
    }),
  },
}

export default MuiRadio
