import { Components, Theme } from '@mui/material'

import palette from 'theme/palette'

const MuiCardActions: Components<Theme>['MuiCardActions'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      '&:first-of-type': {
        borderTop: 0,
      },
      borderTop: `1px solid ${palette.divider}`,
      padding: theme.spacing(2, 4),
    }),
    spacing: ({ theme }) => ({
      '& > :not(:first-of-type)': {
        marginLeft: theme.spacing(4),
      },
    }),
  },
}

export default MuiCardActions
