import { Components, Theme } from '@mui/material'

import palette from 'theme/palette'

const MuiTypography: Components<Theme>['MuiTypography'] = {
  styleOverrides: {
    root: {
      '&.chartAxis': {
        borderRadius: 2,
        color: palette.text.secondary,
        fontSize: 10,
        fontWeight: 500,
        lineHeight: '15px',
        paddingLeft: 2,
        paddingRight: 2,
        textAlign: 'center',
        textTransform: 'uppercase',
      },
      '&.label': {
        fontSize: 10,
        fontWeight: 500,
        lineHeight: '16px',
      },
    },
  },
}

export default MuiTypography
