import { Components, Theme } from '@mui/material'

import { fontSize } from 'theme/typography'

const MuiTooltip: Components<Theme>['MuiTooltip'] = {
  styleOverrides: {
    arrow: {
      color: 'rgba(38, 50, 56, 0.85)',
    },
    tooltip: {
      backgroundColor: 'rgba(40, 50, 56, 0.85)',
      fontSize,
      fontWeight: 600,
    },
  },
}

export default MuiTooltip
