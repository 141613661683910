import { Components, Theme } from '@mui/material'
import { blueGrey } from '@mui/material/colors'

const MuiLinearProgress: Components<Theme>['MuiLinearProgress'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: blueGrey[100],
      borderRadius: theme.shape.borderRadius,
    }),
  },
}

export default MuiLinearProgress
