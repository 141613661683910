import { Components, Theme, typographyClasses } from '@mui/material'
import { iconButtonClasses } from '@mui/material/IconButton'
import { selectClasses } from '@mui/material/Select'

import palette from 'theme/palette'

const MuiInputAdornment: Components<Theme>['MuiInputAdornment'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      [`& + .${selectClasses.outlined}.${selectClasses.outlined}`]: {
        paddingLeft: theme.spacing(2),
      },

      [`& .${iconButtonClasses.root}`]: {
        [`& .${iconButtonClasses.root}`]: {
          fontSize: 16,
        },
        padding: 2,
      },

      [`& .${typographyClasses.root}`]: {
        fontWeight: 600,
        lineHeight: '16px',
      },
      // FIXME
      [`.Mui-disabled > &`]: {
        color: 'inherit',
      },
      color: palette.text.secondary,
      height: 'auto',
    }),
  },
}

export default MuiInputAdornment
