import { Components, Theme } from '@mui/material'
import { inputLabelClasses } from '@mui/material/InputLabel'

import { fontSize } from 'theme/typography'

const MuiInputLabel: Components<Theme>['MuiInputLabel'] = {
  styleOverrides: {
    formControl: {
      [`&.${inputLabelClasses.shrink}`]: {
        transform: 'translate(0px, -7px) scale(0.75)',
      },
      transform: 'translate(0px, 8px) scale(1)',
    },
    outlined: {
      [`&.${inputLabelClasses.shrink}`]: {
        transform: 'translate(8px, -7px) scale(0.75)',
      },
      fontSize,
      top: 0,
      transform: 'translate(8px, 8px) scale(1)',
    },
    root: {
      fontWeight: 600,
    },
  },
}

export default MuiInputLabel
