import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import React from 'react'

function Warning(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path
        clipRule="evenodd"
        d="M14.8007 11.7069C15.3662 12.7235 14.6612 14 13.5302 14H2.46984C1.33884 14 0.633799 12.7235 1.1993 11.7069L6.72946 1.76246C7.29496 0.745847 8.70504 0.745847 9.27054 1.76246L14.8007 11.7069ZM8 4C7.44772 4 7 4.44772 7 5V8C7 8.55228 7.44772 9 8 9C8.55228 9 9 8.55228 9 8V5C9 4.44772 8.55228 4 8 4ZM8 10C7.44772 10 7 10.4477 7 11C7 11.5523 7.44772 12 8 12C8.55228 12 9 11.5523 9 11C9 10.4477 8.55228 10 8 10Z"
        fillRule="evenodd"
      />
    </SvgIcon>
  )
}

export default Warning
