function replaceInterpolationSymbols(string: string, useOne?: boolean) {
  const pluralMatch = string.match(/\{count, plural/)

  if (pluralMatch?.index === 0) return string

  if (useOne) {
    return string.replace(/\{\{([^{}]+)}}/g, '{$1}')
  }

  return string.replace(/\{([^{}]+)}/g, '{{$1}}')
}

export default replaceInterpolationSymbols
