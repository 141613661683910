import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import React from 'react'

function Info(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path d="M8.01663 11.6C8.26108 11.6 8.46952 11.5138 8.64196 11.3414C8.81396 11.1694 8.89996 10.9611 8.89996 10.7167V8.20002C8.89996 7.95557 8.81396 7.75002 8.64196 7.58335C8.46952 7.41668 8.26108 7.33335 8.01663 7.33335C7.77219 7.33335 7.56396 7.41935 7.39196 7.59135C7.21952 7.7638 7.1333 7.97224 7.1333 8.21668V10.7334C7.1333 10.9778 7.21952 11.1834 7.39196 11.35C7.56396 11.5167 7.77219 11.6 8.01663 11.6ZM7.99996 5.73335C8.2333 5.73335 8.43041 5.65291 8.5913 5.49202C8.75263 5.33068 8.8333 5.13335 8.8333 4.90002C8.8333 4.66668 8.75263 4.46935 8.5913 4.30802C8.43041 4.14713 8.2333 4.06668 7.99996 4.06668C7.76663 4.06668 7.56952 4.14713 7.40863 4.30802C7.2473 4.46935 7.16663 4.66668 7.16663 4.90002C7.16663 5.13335 7.2473 5.33068 7.40863 5.49202C7.56952 5.65291 7.76663 5.73335 7.99996 5.73335ZM7.99996 15.2C6.99996 15.2 6.06374 15.0111 5.1913 14.6334C4.3193 14.2556 3.55841 13.7418 2.90863 13.092C2.25841 12.4418 1.74441 11.6805 1.36663 10.808C0.988854 9.93602 0.799965 9.00002 0.799965 8.00002C0.799965 7.00002 0.988854 6.06113 1.36663 5.18335C1.74441 4.30557 2.25841 3.5418 2.90863 2.89202C3.55841 2.2418 4.3193 1.73046 5.1913 1.35802C6.06374 0.986018 6.99996 0.800018 7.99996 0.800018C8.99996 0.800018 9.93885 0.986018 10.8166 1.35802C11.6944 1.73046 12.4584 2.2418 13.1086 2.89202C13.7584 3.5418 14.2693 4.30557 14.6413 5.18335C15.0137 6.06113 15.2 7.00002 15.2 8.00002C15.2 9.00002 15.0137 9.93602 14.6413 10.808C14.2693 11.6805 13.7584 12.4418 13.1086 13.092C12.4584 13.7418 11.6944 14.2556 10.8166 14.6334C9.93885 15.0111 8.99996 15.2 7.99996 15.2Z" />
    </SvgIcon>
  )
}

export default Info
