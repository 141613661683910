import { Components, Theme } from '@mui/material'
import { blueGrey, grey, indigo } from '@mui/material/colors'
import { svgIconClasses } from '@mui/material/SvgIcon'
import { toggleButtonClasses } from '@mui/material/ToggleButton'

import palette from 'theme/palette'

const MuiToggleButton: Components<Theme>['MuiToggleButton'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      '&.-error': {
        [`&.${toggleButtonClasses.selected}`]: {
          background: palette.error.light,
        },

        color: palette.error.main,
      },
      '&.-wide': {
        padding: theme.spacing(1, 5),
      },
      '&disabled, &[disabled]': {
        backgroundColor: 'transparent',
        color: grey[400],
      },
      [`& .${svgIconClasses.root}`]: {
        fontSize: 12,
      },
      [`&.${toggleButtonClasses.selected}`]: {
        backgroundColor: indigo['50'],
        color: palette.primary.main,
      },
      backgroundImage: 'none',
      backgroundPosition: '0% 0%',
      backgroundRepeat: 'repeat',
      border: 'none',
      color: blueGrey['500'],
      fontSize: 12,
      fontWeight: 600,
      height: 24,
      lineHeight: '16px',
      padding: '2px 5px',
      textTransform: 'none',
      whiteSpace: 'normal',
    }),
  },
}

export default MuiToggleButton
