import { Components, Theme } from '@mui/material'

const MuiListItemText: Components<Theme>['MuiListItemText'] = {
  styleOverrides: {
    primary: {
      fontWeight: 600,
    },
    root: ({ theme }) => ({
      marginBottom: `${theme.spacing(1)} !important`,
      marginTop: `${theme.spacing(1)} !important`,
    }),
  },
}

export default MuiListItemText
