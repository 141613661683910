import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import React from 'react'

function Remove(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path d="M4.94997 11.05C5.11664 11.2167 5.3222 11.3 5.56664 11.3C5.81108 11.3 6.01664 11.2167 6.18331 11.05L7.99997 9.23335L9.83331 11.0667C9.99997 11.2334 10.2026 11.3138 10.4413 11.308C10.6804 11.3027 10.8833 11.2167 11.05 11.05C11.2166 10.8834 11.3 10.6778 11.3 10.4334C11.3 10.1889 11.2166 9.98335 11.05 9.81668L9.23331 8.00002L11.0666 6.16668C11.2333 6.00002 11.3137 5.79735 11.308 5.55868C11.3026 5.31957 11.2166 5.11668 11.05 4.95002C10.8833 4.78335 10.6777 4.70002 10.4333 4.70002C10.1889 4.70002 9.98331 4.78335 9.81664 4.95002L7.99997 6.76668L6.16664 4.93335C6.01108 4.76668 5.81108 4.68602 5.56664 4.69135C5.3222 4.69713 5.11664 4.78335 4.94997 4.95002C4.78331 5.11668 4.69997 5.32224 4.69997 5.56669C4.69997 5.81113 4.78331 6.01668 4.94997 6.18335L6.76664 8.00002L4.93331 9.83335C4.76664 9.98891 4.68619 10.1889 4.69197 10.4334C4.69731 10.6778 4.78331 10.8834 4.94997 11.05ZM7.99997 15.2C6.99997 15.2 6.06375 15.0111 5.19131 14.6334C4.31931 14.2556 3.55842 13.7418 2.90864 13.092C2.25842 12.4418 1.74442 11.6805 1.36664 10.808C0.988861 9.93602 0.799973 9.00002 0.799973 8.00002C0.799973 7.00002 0.988861 6.06113 1.36664 5.18335C1.74442 4.30557 2.25842 3.5418 2.90864 2.89202C3.55842 2.2418 4.31931 1.73046 5.19131 1.35802C6.06375 0.986018 6.99997 0.800018 7.99997 0.800018C8.99997 0.800018 9.93886 0.986018 10.8166 1.35802C11.6944 1.73046 12.4584 2.2418 13.1086 2.89202C13.7584 3.5418 14.2693 4.30557 14.6413 5.18335C15.0137 6.06113 15.2 7.00002 15.2 8.00002C15.2 9.00002 15.0137 9.93602 14.6413 10.808C14.2693 11.6805 13.7584 12.4418 13.1086 13.092C12.4584 13.7418 11.6944 14.2556 10.8166 14.6334C9.93886 15.0111 8.99997 15.2 7.99997 15.2Z" />
    </SvgIcon>
  )
}

export default Remove
