import { Components, Theme } from '@mui/material'
import {
  blueGrey,
  green,
  grey,
  indigo,
  orange,
  red,
} from '@mui/material/colors'

const MuiCard: Components<Theme>['MuiCard'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      '&.colored-border': {
        '&.-red': {
          borderColor: red['500'],
        },
      },
      '&.spaced': {
        padding: theme.spacing(2, 6),
      },
      '&.status': {
        '&.-blue': {
          '&:before': {
            backgroundColor: indigo['A700'],
          },
        },
        '&.-blueGrey': {
          '&:before': {
            backgroundColor: blueGrey['500'],
          },
        },
        '&.-green:before': {
          backgroundColor: green.A700,
        },
        '&.-inactive:before': {
          opacity: 0.3,
        },
        '&.-orange': {
          '&:before': {
            backgroundColor: orange['500'],
          },
        },
        '&.-red': {
          '&.striped:before': {
            backgroundColor: red['50'],
          },
          '&:before': {
            backgroundColor: red['500'],
          },
        },
        '&.short:before': {
          bottom: 6,
          top: 6,
        },
        '&:before': {
          backgroundColor: grey['300'],
          borderBottomRightRadius: 4,
          borderTopRightRadius: 4,
          bottom: 8,
          content: '""',
          display: 'block',
          left: 0,
          position: 'absolute',
          top: 8,
          width: 4,
        },
        position: 'relative',
      },
      '&.striped': {
        '&.-red': {
          background: `repeating-linear-gradient(
          -45deg,
          #fff,
          #fff 6px,
          ${red['50']} 6px,
          ${red['50']} 7px
        )`,
        },
        background: `repeating-linear-gradient(
        -45deg,
        #fff,
        #fff 6px,
        ${green['50']} 6px,
        ${green['50']} 7px
      )`,
        backgroundSize: '10px 10px',
      },
    }),
  },
}

export default MuiCard
