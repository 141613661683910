import { Components, Theme } from '@mui/material'

const MuiListSubheader: Components<Theme>['MuiListSubheader'] = {
  styleOverrides: {
    root: {
      textTransform: 'uppercase',
    },
  },
} as const

export default MuiListSubheader
