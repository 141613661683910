import { Components, Theme } from '@mui/material'
import { grey } from '@mui/material/colors'
import { inputClasses } from '@mui/material/Input'

const MuiInput: Components<Theme>['MuiInput'] = {
  styleOverrides: {
    formControl: {
      'label + &': {
        marginTop: 0,
      },
    },
    underline: {
      '&:after': {
        borderBottom: `2px solid ${grey['300']}`,
      },
      '&:before': {
        borderBottom: `1px solid ${grey['300']}`,
      },
      [`&:hover:not(.${inputClasses.disabled}):before`]: {
        '@media (hover: none)': {
          borderBottom: `1px solid ${grey['300']}`,
        },
        borderBottom: `2px solid ${grey['400']}`,
      },
    },
  },
}

export default MuiInput
