import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import React from 'react'

function Error(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path d="M8.01663 8.63335C8.26108 8.63335 8.46952 8.54713 8.64197 8.37468C8.81397 8.20268 8.89997 7.99446 8.89997 7.75002V5.40002C8.89997 5.15557 8.81397 4.95002 8.64197 4.78335C8.46952 4.61669 8.26108 4.53335 8.01663 4.53335C7.77219 4.53335 7.56397 4.61957 7.39197 4.79202C7.21952 4.96402 7.1333 5.17224 7.1333 5.41668V7.76668C7.1333 8.01113 7.21952 8.21668 7.39197 8.38335C7.56397 8.55002 7.77219 8.63335 8.01663 8.63335ZM7.99997 11.6C8.2333 11.6 8.43041 11.5196 8.5913 11.3587C8.75264 11.1974 8.8333 11 8.8333 10.7667C8.8333 10.5334 8.75264 10.336 8.5913 10.1747C8.43041 10.0138 8.2333 9.93335 7.99997 9.93335C7.76664 9.93335 7.56952 10.0138 7.40864 10.1747C7.2473 10.336 7.16664 10.5334 7.16664 10.7667C7.16664 11 7.2473 11.1974 7.40864 11.3587C7.56952 11.5196 7.76664 11.6 7.99997 11.6ZM7.99997 15.2C6.99997 15.2 6.06375 15.0111 5.1913 14.6334C4.3193 14.2556 3.55841 13.7418 2.90864 13.092C2.25841 12.4418 1.74441 11.6805 1.36664 10.808C0.988858 9.93602 0.799969 9.00002 0.799969 8.00002C0.799969 7.00002 0.988858 6.06113 1.36664 5.18335C1.74441 4.30557 2.25841 3.5418 2.90864 2.89202C3.55841 2.2418 4.3193 1.73046 5.1913 1.35802C6.06375 0.986018 6.99997 0.800018 7.99997 0.800018C8.99997 0.800018 9.93886 0.986018 10.8166 1.35802C11.6944 1.73046 12.4584 2.2418 13.1086 2.89202C13.7584 3.5418 14.2693 4.30557 14.6413 5.18335C15.0137 6.06113 15.2 7.00002 15.2 8.00002C15.2 9.00002 15.0137 9.93602 14.6413 10.808C14.2693 11.6805 13.7584 12.4418 13.1086 13.092C12.4584 13.7418 11.6944 14.2556 10.8166 14.6334C9.93886 15.0111 8.99997 15.2 7.99997 15.2Z" />
    </SvgIcon>
  )
}

export default Error
