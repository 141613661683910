import { Components, Theme } from '@mui/material'

const MuiListItem: Components<Theme>['MuiListItem'] = {
  styleOverrides: {
    gutters: ({ theme }) => ({
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    }),
    root: {
      minHeight: 6,
      padding: 0,
    },
  },
}

export default MuiListItem
