import { Components, Theme } from '@mui/material'
import { buttonBaseClasses } from '@mui/material/ButtonBase'

const MuiButtonBase: Components<Theme>['MuiButtonBase'] = {
  styleOverrides: {
    root: {
      [`&.${buttonBaseClasses.disabled}`]: {
        border: 0,
      },
      backgroundImage: 'none',
      backgroundPosition: '0% 0%',
      backgroundRepeat: 'no-repeat',
    },
  },
}

export default MuiButtonBase
