import { Components, Theme } from '@mui/material'
import { blueGrey } from '@mui/material/colors'

const MuiTabs: Components<Theme>['MuiTabs'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      '&.-underlined': {
        boxShadow: `inset 0px -2px ${theme.palette.border.main}`,
      },
      color: blueGrey['500'],
      minHeight: theme.spacing(8),
      zIndex: 1,
    }),
  },
}

export default MuiTabs
