import { Components, Theme } from '@mui/material'

import palette from 'theme/palette'

const MuiListItemIcon: Components<Theme>['MuiListItemIcon'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: palette.text.primary,
      minWidth: theme.spacing(6),
    }),
  },
}

export default MuiListItemIcon
