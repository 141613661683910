import { Components, Theme, typographyClasses } from '@mui/material'
import { iconButtonClasses } from '@mui/material/IconButton'

import typography from 'theme/typography'

const MuiDialogTitle: Components<Theme>['MuiDialogTitle'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      [`& .${iconButtonClasses.root}.close`]: {
        marginLeft: theme.spacing(2),
      },
      [`&.${typographyClasses.h6}`]: {
        ...typography.h4,
      },
      alignItems: 'flex-start',
      display: 'flex',

      justifyContent: 'space-between',

      padding: theme.spacing(4),
    }),
  },
}

export default MuiDialogTitle
