import { Components, Theme } from '@mui/material'
import { chipClasses } from '@mui/material/Chip'
import { blueGrey, grey, indigo } from '@mui/material/colors'

import palette from 'theme/palette'
import typography from 'theme/typography'

const MuiChip: Components<Theme>['MuiChip'] = {
  styleOverrides: {
    clickable: {
      '&:active': {
        boxShadow: 'none',
      },
      '&:hover, &:focus': {
        backgroundColor: blueGrey['100'],
      },
    },
    colorError: {
      '&.-filled': {
        backgroundColor: palette.error.main,
      },
      [`& .${chipClasses.icon}`]: {
        fill: palette.error.main,
      },
      backgroundColor: palette.error.light,
    },
    colorInfo: {
      '&.-filled': {
        backgroundColor: indigo['700'],
      },
      [`& .${chipClasses.icon}`]: {
        fill: indigo['700'],
      },
      backgroundColor: indigo['50'],
    },
    colorSuccess: {
      '&.-filled': {
        backgroundColor: palette.success.main,
      },
      [`& .${chipClasses.icon}`]: {
        fill: palette.success.main,
      },
      backgroundColor: palette.success.light,
    },
    colorWarning: {
      '&.-filled': {
        backgroundColor: palette.warning.main,
      },
      [`& .${chipClasses.icon}`]: {
        fill: palette.warning.main,
      },
      backgroundColor: palette.warning.light,
    },
    deletable: {
      '&:focus': {
        backgroundColor: blueGrey['100'],
      },
    },
    deleteIcon: ({ theme }) => ({
      '&:hover': {
        color: blueGrey['700'],
      },
      color: blueGrey['500'],
      height: theme.spacing(3),
      margin: theme.spacing(0, 1, 0, 0),
      width: theme.spacing(3),
    }),
    icon: ({ theme }) => ({
      fill: grey['500'],
      fontSize: 12,
      margin: theme.spacing(0, 1),
    }),
    label: ({ theme }) => ({
      ...typography.h5,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    }),
    root: {
      '&.-filled': {
        backgroundColor: grey['500'],
        color: 'white',
      },
      backgroundColor: grey['200'],
      borderRadius: 4,
      color: palette.text.primary,
      height: 16,
    },
  },
}

export default MuiChip
