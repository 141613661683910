import { Components, Theme } from '@mui/material'

const MuiPaper: Components<Theme>['MuiPaper'] = {
  variants: [
    {
      props: { variant: 'container' },
      style: {
        background: '#fff',
        borderRadius: 4,
        boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.0415)',
      },
    },
  ],
}

export default MuiPaper
