import { Components, Theme } from '@mui/material'

const MuiFormControl: Components<Theme>['MuiFormControl'] = {
  styleOverrides: {
    marginNormal: ({ theme }) => ({
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(3),
    }),
    root: {},
  },
}

export default MuiFormControl
