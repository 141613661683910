import {
  blueGrey,
  green,
  grey,
  indigo,
  orange,
  red,
} from '@mui/material/colors'

const palette = {
  action: {
    disabled: grey['300'],
  },
  background: {
    default: '#f2f3f9',
  },
  border: {
    dark: blueGrey['100'],
    grid: '#b6b6b6',
    main: blueGrey['50'],
  },
  button: {
    disabled: grey['400'],
  },
  default: {
    main: blueGrey['500'],
  },
  divider: grey['300'],
  error: {
    light: red['50'],
    main: red['500'],
  },
  info: {
    main: blueGrey['100'],
  },
  primary: {
    light: indigo['50'],
    main: indigo.A700,
  },
  secondary: {
    main: red['500'],
  },
  success: {
    light: green[50],
    main: green.A700,
  },
  text: {
    disabled: grey['400'],
    primary: blueGrey['900'],
    secondary: blueGrey['500'],
  },
  warning: {
    light: orange['50'],
    main: orange['500'],
  },
} as const

export default palette
