import { Components, Theme } from '@mui/material'
import { blueGrey } from '@mui/material/colors'

const MuiFormLabel: Components<Theme>['MuiFormLabel'] = {
  styleOverrides: {
    root: {
      color: blueGrey[500],
    },
  },
}

export default MuiFormLabel
