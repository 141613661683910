import type { CSSProperties } from 'react'

import { spacing } from 'theme/spacing'

const spaces = Array.from({ length: 10 }).reduce<Record<string, CSSProperties>>(
  (acc, _, i) => ({
    ...acc,

    [`.-mb${i}`]: { marginBottom: `${spacing * i}px !important` },
    [`.-ml${i}`]: { marginLeft: `${spacing * i}px !important` },
    [`.-mr${i}`]: { marginRight: `${spacing * i}px !important` },
    // margin
    [`.-mt${i}`]: { marginTop: `${spacing * i}px !important` },

    [`.-pb${i}`]: { paddingBottom: `${spacing * i}px !important` },
    [`.-pl${i}`]: { paddingLeft: `${spacing * i}px !important` },
    [`.-pr${i}`]: { paddingRight: `${spacing * i}px !important` },
    // padding
    [`.-pt${i}`]: { paddingTop: `${spacing * i}px !important` },
  }),
  {}
)

export default spaces
