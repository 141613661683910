import { Components, Theme } from '@mui/material'
import { checkboxClasses } from '@mui/material/Checkbox'
import { grey, indigo } from '@mui/material/colors'
import { iconClasses } from '@mui/material/Icon'
import { iconButtonClasses } from '@mui/material/IconButton'

const MuiCheckbox: Components<Theme>['MuiCheckbox'] = {
  defaultProps: {
    color: 'primary',
  },
  styleOverrides: {
    colorPrimary: {
      '&:hover': {
        backgroundColor: indigo[50],
        color: indigo['700'],
      },

      [`&.${checkboxClasses.disabled}`]: {
        [`& .${iconButtonClasses.root}`]: {
          position: 'relative',
          zIndex: 2,
        },

        color: grey['400'],
      },
    },
    root: ({ theme }) => ({
      [`& .${iconClasses.root}`]: {
        fontSize: 16,
      },
      borderRadius: theme.spacing(1),
      color: grey['300'],

      padding: 4,
    }),
  },
}

export default MuiCheckbox
