import { Components, Theme } from '@mui/material'
import { buttonClasses } from '@mui/material/Button'
import { circularProgressClasses } from '@mui/material/CircularProgress'
import {
  blueGrey,
  common,
  deepOrange,
  grey,
  indigo,
} from '@mui/material/colors'

import palette from 'theme/palette'
import { fontSize } from 'theme/typography'

const MuiButton: Components<Theme>['MuiButton'] = {
  defaultProps: {
    color: 'default',
    variant: 'outlined',
  },
  styleOverrides: {
    contained: ({ theme }) => ({
      '&.only-icon': {
        minWidth: 32,
      },
      '&:active': {
        boxShadow: 'none',
      },
      '&:hover': {
        boxShadow: 'none',
      },
      [`& .${buttonClasses.endIcon}`]: {
        marginLeft: 'auto',
        marginRight: 0,
        paddingLeft: theme.spacing(2),
      },
      [`&.${buttonClasses.focusVisible}`]: {
        boxShadow: 'none',
      },
      'a:link&, a:visited&': {
        color: palette.text.primary,
      },
      boxShadow: 'none',
      justifyContent: 'flex-start',
    }),
    containedPrimary: ({ theme }) => ({
      [`&.${buttonClasses.disabled}`]: {
        backgroundColor: grey['200'],
      },
      [`&:not(.${buttonClasses.disabled}) .${buttonClasses.startIcon}`]: {
        color: common.white,
        marginLeft: theme.spacing(-2),
      },
      'a:link&, a:visited&': {
        color: common.white,
      },
      justifyContent: 'center',
      padding: '8px 24px',
    }),
    containedSecondary: ({ theme }) => ({
      'a:link&, a:visited&': {
        color: common.white,
      },
      justifyContent: 'center',
      padding: theme.spacing(2, 6),
    }),
    iconSizeMedium: {
      '& > *:first-of-type': {
        fontSize: 16,
      },
    },
    outlined: {
      '&.only-icon': {
        [`& .${buttonClasses.startIcon}`]: {
          marginRight: 0,
        },
        minWidth: 'auto',
        padding: 7,
      },
      '&:hover': {
        backgroundColor: indigo['50'],
      },
      [`&.${buttonClasses.disabled}`]: {
        backgroundColor: 'transparent',
        color: grey['400'],
      },
      border: `1px solid ${grey['300']}`,
      padding: '7px 23px',
    },
    root: ({ theme }) => ({
      [`& .${circularProgressClasses.root}`]: {
        fontSize: 16,
        left: '50%',
        margin: '-0.5em 0 0 -0.5em',
        position: 'absolute',
        top: '50%',
      },
      [`&.${buttonClasses.disabled}`]: {
        color: grey['400'],
      },
      backgroundImage: 'none',
      backgroundPosition: '0% 0%',
      backgroundRepeat: 'repeat',
      fontSize: 12,
      fontWeight: 600,
      height: 'auto',
      lineHeight: '16px',
      padding: theme.spacing(2),
      textTransform: 'none',
      whiteSpace: 'normal',
    }),
    sizeSmall: {
      '&.only-icon': {
        padding: 3,
      },
      fontSize,
      lineHeight: 1,
    },
    startIcon: ({ theme }) => ({
      marginLeft: 0,
      marginRight: theme.spacing(2),
    }),
    text: ({ theme }) => ({
      '&:hover': {
        [`& .${buttonClasses.startIcon}`]: {
          color: blueGrey['900'],
        },
        backgroundColor: 'transparent',
        color: blueGrey['900'],
        textDecoration: 'underline',
      },
      [`& .${buttonClasses.startIcon}`]: {
        color: palette.text.secondary,
        marginRight: theme.spacing(1),
      },
      [`&.${buttonClasses.disabled}`]: {
        [`& .${buttonClasses.startIcon}`]: {
          color: grey['400'],
        },
        backgroundColor: 'transparent',
        color: grey['400'],
      },
      minWidth: 'auto',
      padding: 0,
      textDecoration: 'underline',
    }),
    textPrimary: {
      '&:hover': {
        [`& .${buttonClasses.startIcon}`]: {
          color: indigo['700'],
        },
        backgroundColor: 'transparent',
        color: indigo['700'],
      },
      [`& .${buttonClasses.startIcon}`]: {
        color: palette.primary.main,
      },
      color: palette.primary.main,
    },
    textSecondary: {
      '&:hover': {
        [`& .${buttonClasses.startIcon}`]: {
          color: deepOrange['900'],
        },
        backgroundColor: 'transparent',
        color: deepOrange['900'],
      },
      [`& .${buttonClasses.startIcon}`]: {
        color: palette.secondary.main,
      },
      color: palette.secondary.main,
    },
    textSizeSmall: {
      padding: 0,
    },
  },
  variants: [
    {
      props: { color: 'default', variant: 'contained' },
      style: {
        '&:hover': {
          [`& .${buttonClasses.startIcon}`]: {
            color: blueGrey['900'],
          },
          backgroundColor: grey['300'],
          color: blueGrey['900'],
        },
        [`&.${buttonClasses.disabled}`]: {
          [`& .${buttonClasses.startIcon}`]: {
            color: grey['400'],
          },
          backgroundColor: grey['200'],
          color: grey['400'],
        },
        backgroundColor: grey['200'],
        color: palette.text.primary,
      },
    },
    {
      props: { color: 'default', variant: 'outlined' },
      style: {
        color: blueGrey['900'],
      },
    },
  ],
}

export default MuiButton
