import { Theme } from '@mui/material'

const PrivateNotchedOutline = {
  styleOverrides: {
    legendLabelled: {
      '& span': {
        paddingLeft: 4,
        paddingRight: 4,
      },
      fontSize: 9,
      fontWeight: 600,
    },
    root: ({ theme }: { theme: Theme }) => ({
      paddingLeft: theme.spacing(1),
    }),
  },
}

export default PrivateNotchedOutline
