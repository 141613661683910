import { Components, dialogTitleClasses, Theme } from '@mui/material'

const MuiDialogContent: Components<Theme>['MuiDialogContent'] = {
  styleOverrides: {
    dividers: ({ theme }) => ({
      padding: theme.spacing(4),
    }),
    root: ({ theme }) => ({
      [`:not(.${dialogTitleClasses.root} + &)`]: {
        // dialog without title
        paddingTop: theme.spacing(4),
      },
      padding: theme.spacing(0, 4, 4),
    }),
  },
}

export default MuiDialogContent
