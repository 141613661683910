import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import React from 'react'

function Success(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path d="M7.06663 8.93335L5.7333 7.60002C5.56663 7.43335 5.36663 7.35002 5.1333 7.35002C4.89996 7.35002 4.69441 7.43891 4.51663 7.61668C4.34996 7.78335 4.26663 7.98891 4.26663 8.23335C4.26663 8.4778 4.34996 8.68335 4.51663 8.85002L6.44996 10.7834C6.62774 10.9611 6.8333 11.05 7.06663 11.05C7.29996 11.05 7.50552 10.9611 7.6833 10.7834L11.5333 6.93335C11.7 6.76668 11.7833 6.56668 11.7833 6.33335C11.7833 6.10002 11.6944 5.89446 11.5166 5.71668C11.35 5.55002 11.1444 5.46668 10.9 5.46668C10.6555 5.46668 10.45 5.55002 10.2833 5.71668L7.06663 8.93335ZM7.99996 15.2C6.99996 15.2 6.06374 15.0111 5.1913 14.6334C4.3193 14.2556 3.55841 13.7418 2.90863 13.092C2.25841 12.4418 1.74441 11.6805 1.36663 10.808C0.988854 9.93602 0.799965 9.00002 0.799965 8.00002C0.799965 7.00002 0.988854 6.06113 1.36663 5.18335C1.74441 4.30557 2.25841 3.5418 2.90863 2.89202C3.55841 2.2418 4.3193 1.73046 5.1913 1.35802C6.06374 0.986018 6.99996 0.800018 7.99996 0.800018C8.99996 0.800018 9.93885 0.986018 10.8166 1.35802C11.6944 1.73046 12.4584 2.2418 13.1086 2.89202C13.7584 3.5418 14.2693 4.30557 14.6413 5.18335C15.0137 6.06113 15.2 7.00002 15.2 8.00002C15.2 9.00002 15.0137 9.93602 14.6413 10.808C14.2693 11.6805 13.7584 12.4418 13.1086 13.092C12.4584 13.7418 11.6944 14.2556 10.8166 14.6334C9.93885 15.0111 8.99996 15.2 7.99996 15.2Z" />
    </SvgIcon>
  )
}

export default Success
