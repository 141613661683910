import { Components, Theme } from '@mui/material'

const MuiSnackbar: Components<Theme>['MuiSnackbar'] = {
  defaultProps: {
    'data-testid': 'notification',
  },
  styleOverrides: {
    anchorOriginTopRight: ({ theme }) => ({
      marginTop: theme.spacing(5),
      [theme.breakpoints.up('sm')]: {
        right: theme.spacing(4),
      },
    }),
  },
}

export default MuiSnackbar
