import { createTheme, ThemeOptions } from '@mui/material'
import { CSSObject } from 'tss-react'

import palette from 'theme/palette'
import { spacing } from 'theme/spacing'
import typography from 'theme/typography'
import { ThemeApp } from 'types/theme'

import * as components from './components'

interface AppThemeOptions extends ThemeOptions {
  app: Omit<ThemeApp, 'header'>
  chat: {
    collapsed: {
      height: number
      width: number
    }
    height: number
  }
}

export const themeOptions: AppThemeOptions = {
  app: {
    menu: {
      firstLevel: {
        width: 68,
      },
    },
    topBar: {
      gutterBottom: 32,
      height: 40,
    },
  },
  breakpoints: {
    values: {
      lg: 1280,
      md: 960,
      sm: 600,
      xl: 1920,
      xs: 0,
    },
  },
  chat: {
    collapsed: {
      height: 56,
      width: 320,
    },
    height: 350,
  },
  components,
  palette,
  spacing,
  typography,
  zIndex: {
    sidebar: 2600,
    snackbar: 10000,
    // From style.scss for .ss-toast, because of legacy views
    tooltip: 3000,
  },
}

const theme = createTheme(themeOptions)

type Theme = typeof theme

if (import.meta.env.DEV) window.theme = theme

export function themeTypography(key: keyof Theme['typography']): CSSObject {
  return theme.typography[key] as CSSObject
}

export default theme
