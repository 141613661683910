import { Components, Theme } from '@mui/material'

const MuiFormHelperText: Components<Theme>['MuiFormHelperText'] = {
  styleOverrides: {
    contained: ({ theme }) => ({
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    }),
    root: {
      fontSize: 10,
    },
  },
}

export default MuiFormHelperText
