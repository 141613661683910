import { Components, Theme } from '@mui/material'

import palette from 'theme/palette'

const MuiSvgIcon: Components<Theme>['MuiSvgIcon'] = {
  styleOverrides: {
    fontSizeInherit: {
      fontSize: 'inherit', // This is default, but root font size changes it
    },
    fontSizeSmall: {
      fontSize: 10,
    },
    root: {
      '&.-green': {
        color: palette.success.main,
      },
      '&.-grey': {
        color: palette.button.disabled,
      },

      '&.-orange': {
        color: palette.warning.main,
      },

      display: 'block',

      fontSize: 16,
    },
  },
}

export default MuiSvgIcon
