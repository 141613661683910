import { ApolloClient } from '@apollo/client'
import { isEqual } from 'lodash-es'
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'

import {
  getLocalStorageItem,
  setLocalStorageItem,
} from 'utils/safeLocalStorage'

import translate from './translate'

// We allow defaultMessage to only be string, and both defaultMessage and id are required
export type ValidMessageDescriptor = { defaultMessage: string; id: string }

export const formats = {
  date: {
    base: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    },
  },
} as const

const onError = () => {}

let defineMessagesEventListenerAdded = false
let pendingDefinedMessages: Record<string, ValidMessageDescriptor> = {}

export function defineMessages<
  T extends Record<string, ValidMessageDescriptor>,
>(messages: T) {
  if (defineMessagesEventListenerAdded) {
    const event = new CustomEvent('defineMessages', { detail: messages })

    window.dispatchEvent(event)
  } else {
    pendingDefinedMessages = {
      ...pendingDefinedMessages,
      ...messages,
    }
  }

  return messages
}

const storedItemKey = '_M_' + window.locale_name

export type Props = {
  apolloClient?: ApolloClient<unknown>
  children: ReactNode
  locale?: string
}

function LanguageProvider({
  apolloClient,
  children,
  locale = window.localeName,
}: Props) {
  const [messages, setMessages] = useState<Record<string, string>>(
    JSON.parse(getLocalStorageItem(storedItemKey) ?? '{}')
  )

  const onNewMessages = useCallback(
    (translatedMessages: Record<string, string>) => {
      setMessages((messages) => {
        const newMessages = {
          ...messages,
          ...translatedMessages,
        }

        // Do deep comparison to check if messages need to be updated
        // This helps avoid unecessary renders
        if (isEqual(messages, newMessages)) return messages

        return newMessages
      })
    },
    []
  )

  useEffect(() => {
    if (!apolloClient) return

    const listener = translate(apolloClient, onNewMessages) as EventListener

    window.addEventListener('defineMessages', listener)

    defineMessagesEventListenerAdded = true

    if (Object.keys(pendingDefinedMessages).length)
      defineMessages(pendingDefinedMessages)

    pendingDefinedMessages = {}

    return () => {
      window.removeEventListener('defineMessages', listener)
    }
  }, [apolloClient, onNewMessages])

  useEffect(() => {
    setLocalStorageItem(storedItemKey, JSON.stringify(messages))
  }, [messages])

  return (
    <IntlProvider
      formats={formats}
      locale={locale}
      messages={messages}
      onError={onError}
    >
      {children}
    </IntlProvider>
  )
}

export default LanguageProvider
