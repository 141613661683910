import { Components, Theme } from '@mui/material'
import { tabClasses } from '@mui/material/Tab'

import palette from 'theme/palette'

const MuiTab: Components<Theme>['MuiTab'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      '& + &': {
        marginLeft: 8,
      },
      '&:hover': {
        [`&.${tabClasses.selected}`]: {
          backgroundColor: theme.palette.primary.light,
        },
        backgroundColor: theme.palette.grey['200'],
      },
      minHeight: theme.spacing(8),
      minWidth: 'auto',
      padding: theme.spacing(2, 2),
      [theme.breakpoints.up('sm')]: {
        minWidth: 'auto',
      },
    }),
    textColorInherit: {
      '&:link': {
        color: 'inherit',
      },
      [`&.${tabClasses.selected}`]: {
        color: palette.primary.main,
      },
    },
  },
}

export default MuiTab
