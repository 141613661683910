import { Components, Theme } from '@mui/material'

const MuiFilledInput: Components<Theme>['MuiFilledInput'] = {
  styleOverrides: {
    input: ({ theme }) => ({
      '&[type=text]': {
        padding: theme.spacing(2),
      },
    }),
  },
}

export default MuiFilledInput
