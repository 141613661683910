import { Components, Theme } from '@mui/material'

import spaces from 'components/PageLayout/spaces'

const margins = Object.entries(spaces).reduce(
  (acc, [key, val]) => ({
    ...acc,
    [`&${key}`]: val,
  }),
  {}
)

const MuiDivider: Components<Theme>['MuiDivider'] = {
  styleOverrides: {
    root: {
      ...margins,
    },
  },
}

export default MuiDivider
